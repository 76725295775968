import {ApiClientInterface} from "./clients";
import {type OnboardingDataType, Profile, UuidResult} from './models';
import {UserGender} from "./enums";

export class ProfileManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async get(): Promise<Profile> {
    return this.apiClient.get("");
  }

  public async changeGender(gender: UserGender): Promise<void> {
    return this.apiClient.post("/change-gender", {gender});
  }

  public async signContract(): Promise<string> {
    return this.apiClient.post("/sign-contract");
  }

  public async requestCode(): Promise<UuidResult> {
    return this.apiClient.post('request-code');
  }

  public async confirmCode(confirmationUuid: string, verificationCode: string, documentUuid: string): Promise<void> {
    return this.apiClient.post("confirm-code", {confirmationUuid, verificationCode, documentUuid});
  }
  
  public async addOnboardingAnswers(onboardingData: OnboardingDataType): Promise<void> {
    return this.apiClient.post("add-onboarding-answers", onboardingData);
  }
}