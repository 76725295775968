import React, {AllHTMLAttributes, FC, PropsWithChildren} from 'react';
import styles from './AddOnboardingModal.module.scss';
import Modal from '../Modal';

export interface ModalProps extends AllHTMLAttributes<HTMLDivElement> {
  active: boolean;
  onClose: () => void;
}

const AddOnboardingModal: FC<PropsWithChildren<ModalProps>> = ({active, onClose, children, ...props}) => {
  return  (<Modal className={styles.myModal} active={active} onClose={onClose}>
    <div className={styles.myModal__content}>
      {children}
    </div>
  </Modal>);
};

export default AddOnboardingModal;