import React from 'react';
import styles from './OnboardingGreeting.module.scss';

const OnboardingGreeting = () => {
  return (
    <div className={styles.greetingContent}>
      <div className={`'text-black' ${styles.welcomeTitle}`}>Добро пожаловать на платформу Conomica!</div>
      <div className={styles['text-gray-3']}>Компания <span className={styles.boldText}>Conomica </span>
        занимается инвестициями в дебиторскую задолженность юридических лиц. Мы выкупаем инвестопригодные долги с
        дисконтом и взыскиваем полную сумму. Полученный финансовый результат
        распределяется между вложившимися в проект инвесторами с учетом агентского
        вознаграждения <span className={styles.boldText}>Conomica</span>.
        Такой вид инвестиций помогает получить от <span className={styles.boldText}>30%</span> годовых в долгосрочной
        перспективе и расширить инвестиционный портфель, поддерживая при этом российский бизнес.</div>
    </div>
  );
};
export default OnboardingGreeting;