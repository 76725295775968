import React from 'react';
import {CustomLink, Flex, PrimaryButton} from "../../../index";
import {AlignItems, JustifyContent} from "../../../layout/Flex/Flex";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "utils";

export enum BannerBlockVariant {
    IDENTIFICATION = 'identification',
    CONTRACT = 'contract',
    DEPOSIT = 'deposit',
    VERIFIED = 'verified',
    SURVEY = 'survey',
}
interface Props {
    variant: BannerBlockVariant;
    params?: string
}

type IBlock = Record<BannerBlockVariant, {
    textWarning: string,
    button?: {
        route: RouteDictionary,
        text: string
    }
}>


const blockVariant: IBlock = {
  [BannerBlockVariant.IDENTIFICATION]: {
    textWarning: 'Чтобы пользоваться услугами Платформы, вам необходимо пройти верификацию инвестора',
    button: {
      route: RouteDictionary.IDENTIFICATION,
      text: 'Пройти'
    }
  },
  [BannerBlockVariant.CONTRACT]: {
    textWarning: 'Чтобы начать инвестировать, вам необходимо подписать Агентский договор',
    button: {
      route: RouteDictionary.SIGN_CONTRACT,
      text: 'Перейти к заключению'
    }
  },
  [BannerBlockVariant.DEPOSIT]: {
    textWarning: 'Ваши данные находятся на проверке у администратора. Обычно это занимает до 1-2\n' +
            '                                    рабочих дней',
  },
  [BannerBlockVariant.VERIFIED]: {
    textWarning: 'Пополните счет, чтобы начать инвестировать в проекты',
    button: {
      route: RouteDictionary.DEPOSIT,
      text: 'Пополнить'
    }
  },
  [BannerBlockVariant.SURVEY]: {
    textWarning: 'Оцените опыт работы с Conomica, опрос займет не больше 1 минуты',
    button: {
      route: RouteDictionary.SURVEY,
      text: 'Пройти опрос'
    }
  },
};

const BannerBlock = ({variant, params}: Props) => {
  const textWarning = blockVariant[variant].textWarning;
  const button = blockVariant[variant].button;
  const isSignContract = button?.route === RouteDictionary.SIGN_CONTRACT;

  return (
    <Flex justifyContent={JustifyContent.BETWEEN} alignItems={AlignItems.CENTER}>
      <div>
        {textWarning}
      </div>
      {button && <div onClick={() =>isSignContract && ym(90215442,'reachGoal','sign-agency-offer-button')}>
        <PrimaryButton           color={PrimaryButtonColor.GREEN}>
          <CustomLink to={button.route} params={{uuid: params}}>{button.text}</CustomLink>
        </PrimaryButton>
      </div>
      }
    </Flex>
  );
};

export default BannerBlock;