import React, {createContext, FC, ReactNode, useContext, useState} from 'react';
import type {OnboardingDataType} from '../../api-client';

interface OnboardingContextType {
	currentStep: number;
	isActive: boolean;
	changeCurrentStep: (step: number) => void;
	setIsActive: (isActive: boolean) => void;
	isMobile: boolean;
	setIsMobile: (isMobile: boolean) => void;
    jsonData: OnboardingDataType | null;
	updateJsonData: <K extends keyof OnboardingDataType>(key: K, value: OnboardingDataType[K]) => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

interface OnboardingProviderProps {
	children: ReactNode;
}

export const OnboardingProvider: FC<OnboardingProviderProps> = ({children}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [jsonData, setJsonData] = useState<OnboardingDataType>({} as OnboardingDataType);

  const changeCurrentStep = (step: number) => setCurrentStep(step);

  const updateJsonData = <K extends keyof OnboardingDataType>(
    key: K,
    value: OnboardingDataType[K]
  ) => {
    setJsonData(prevData => ({
      ...(prevData || {}),
      [key]: value,
    }));
  };

  return (
    <OnboardingContext.Provider value={{
      currentStep,
      isActive,
      changeCurrentStep,
      setIsActive,
      isMobile,
      setIsMobile,
      jsonData,
      updateJsonData,
    }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};
