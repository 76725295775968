import React from 'react';
import styles from './OnboardingGreetingExplanation.module.scss';

const OnboardingGreetingExplanation = () => {
  return (
    <div className={styles.explanationContent}>
      <div className={styles.explanationContent__paragraphs}>
        <div className={styles.explanationContent__firstParagraph}>Компания Conomica занимается инвестициями
          в дебиторскую задолженность юридических лиц. Мы выкупаем инвестопригодные долги
          с дисконтом и взыскиваем полную сумму.
        </div>
        <div className={styles.explanationContent__secondParagraph}>Полученный финансовый результат
          распределяется между вложившимися в
          проект инвесторами с учетом агентского вознаграждения Conomica. Такой вид инвестиций помогает получить от
          30% годовых в долгосрочной перспективе и расширить инвестиционный портфель, поддерживая при этом российский
          бизнес.
        </div>
      </div>
    </div>
  );
};

export default OnboardingGreetingExplanation;
