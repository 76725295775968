import React, {useContext} from "react";
import IdentificationFileUpload from "../IdentificationFileUpload";
import IdentificationContext from "../../../context/IdentificationContext";
import {UploadedFile} from "../../../../api-client";

const FirstScanUpload = () => {
  const context: any = useContext(IdentificationContext);

  const loadFiles = (files: Array<UploadedFile>) => {
    ym(90215442,'reachGoal','verification-passport-1-input');
    context.setFirstPassportScans(files);
  };

  return (
    <IdentificationFileUpload
      prefix="first"
      text="Разворот с фотографией"
      files={context.firstPassportScans}
      onUploaded={loadFiles}
    />
  );
};

export default FirstScanUpload;