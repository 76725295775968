import React, {useEffect} from "react";
import {useAppDispatch, useEffectOnUpdate, useNavigateByName, useProfileData} from "../../../hooks";
import {createAuthTokenManager, createProfileManager} from "../../../di";
import {RouteDictionary} from "../../../utils";

import {set} from "../../../store/profile";

const RedirectAfterAuth = () => {
  const navigate = useNavigateByName();
  const profileData = useProfileData();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const checkToken = async () => {
      const tokenExists = await createAuthTokenManager().has();
      if (!tokenExists) {
        navigate(RouteDictionary.ENTER);
        return;
      }
    };
    (async () => {
      await checkToken();
      if (!profileData.uuid) {
        await initProfile();
      }
    })();
  }, []);

  const initProfile = async () => {
    const manager = await createProfileManager();
    const profile = await manager.get();
    await dispatch(set(profile));
  };

  useEffectOnUpdate(() => {
    (async () => {
      if (!profileData.lenderVerified) {
        await navigate(RouteDictionary.PRIMARY_MARKET);
        return;
      }
      await navigate(RouteDictionary.MY_MONEY);

    })();
  }, [profileData.uuid]);


  return (
    <div className="redirect-after-auth">
      Перенаправляем вас, пожалуйста, подождите...
    </div>
  );
};

export default RedirectAfterAuth;