import React from 'react';
import {CommonInput, RadioButton} from '../../common';
import RadioButtonGroup, {Direction} from '../../common/form/RadioButtonGroup/RadioButtonGroup';
import styles from './OnboardingRadioModal.module.scss';
import {useOnboarding} from '../OnboardingModalContext';

interface OnboardingRadioModalProps {
  currentStep: number;
}

export const projectOptions = [
  '1 проект',
  'От 2 до 5 проектов',
  'От 5 до 10 проектов',
  'Более 10 проектов',
];

export const sourceOptions = [
  {label: 'По рекомендации', id: 'recommend'},
  {label: 'Youtube, RuTube, VK', id: 'social'},
  {label: 'Мероприятие', id: 'advent'},
  {label: 'Telegram', id: 'telegram'},
  {label: 'Другое', id: 'other'},
];

const OnboardingRadioModal = ({currentStep}: OnboardingRadioModalProps) => {
  const {jsonData, updateJsonData} = useOnboarding();

  const selectedProjectsOption = jsonData?.projectsCount || '';
  const selectedSourceOption = jsonData?.sourceDetails?.option || '';
  const inputSource = jsonData?.sourceDetails?.details || '';

  const handleProjectsRadioChange = (option: string) => {
    updateJsonData('projectsCount', option);
  };

  const handleSourceInputChange = (option: string, value: string) => {
    updateJsonData('sourceDetails', {option, details: value});
  };

  const handleSourceRadioChange = (option: string) => {
    updateJsonData('sourceDetails', {option, details: ''});
  };

  return (
    <>
      {currentStep === 2 && (
        <div className={styles.radioContent}>
          <div className={styles.radioTitle}>
            Портфель из какого количества проектов вы бы хотели собрать?
          </div>
          <div className={styles['text-gray-3']}>
            Минимальный порог в Conomica составляет 300 тыс. рублей в один проект. Мы рекомендуем
            собирать портфель минимум из 10 проектов, то есть сумма инвестиций должна быть от 3 млн. рублей.
          </div>
          <RadioButtonGroup direction={Direction.VERTICAL}>
            {projectOptions.map((option) => (
              <RadioButton
                key={option}
                checked={selectedProjectsOption === option}
                onChange={() => handleProjectsRadioChange(option)}
              >
                {option}
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </div>
      )}
      {currentStep === 3 && (
        <div className={styles.radioContent}>
          <div className={styles.radioTitle}>Откуда узнали о нас?</div>
          <div className={styles.radioButtons__group}>
            {sourceOptions.map(({label, id}) => (
              <React.Fragment key={label}>
                <RadioButton
                  checked={selectedSourceOption === label}
                  onChange={() => handleSourceRadioChange(label)}
                >
                  {label}
                </RadioButton>
                {selectedSourceOption === label && (
                  <CommonInput
                    id={id}
                    type="text"
                    title="Расскажите подробнее"
                    value={inputSource}
                    onChange={(value) => handleSourceInputChange(label, value)}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default OnboardingRadioModal;
