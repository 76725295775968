import React from 'react';
import {useOnboarding} from '../OnboardingModalContext';
import styles from './OnboardingChoosePurposes.module.scss';
import stonks from '../../../assets/images/svg/stonks.svg';
import incomeIncrease from '../../../assets/images/svg/income-increase.svg';
import otherPurposes from '../../../assets/images/svg/other-purposes.svg';
import roundIncome from '../../../assets/images/svg/round-income.svg';

export const purposes = [
  {label: 'Пассивный доход', img: roundIncome},
  {label: 'Диверсификация портфеля', img: stonks},
  {label: 'Высокая доходность', img: incomeIncrease},
  {label: 'Другое', img: otherPurposes},
];

const OnboardingChoosePurposes = () => {
  const {jsonData, updateJsonData} = useOnboarding();

  const selectedPurposes = jsonData?.selectedPurposes || [];

  const handleSelect = (item: string) => {
    const updatedSelection = selectedPurposes.includes(item)
      ? selectedPurposes.filter((selected) => selected !== item)
      : [...selectedPurposes, item];

    updateJsonData('selectedPurposes', updatedSelection);
  };

  const isSelected = (item: string) => selectedPurposes.includes(item);

  return (
    <div className={styles.radioContent}>
      <>
        <div className={styles.radioTitle}>
          Какие у вас цели при работе с платформой Conomica?
        </div>
        <div className={styles['text-gray-3']}>Выберите все подходящие цели</div>
        <div className={styles.checkPurposes}>
          {purposes.map(({label, img}) => (
            <div
              key={label}
              className={`${styles.checkPurposes__item} ${
                isSelected(label) ? styles['selected'] : ''
              }`}
              onClick={() => handleSelect(label)}
            >
              <div className={styles.checkPurposes__itemTitle}>{label}</div>
              <img
                src={img}
                alt={label}
                className={styles.checkPurposes__itemImg}
              />
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default OnboardingChoosePurposes;
