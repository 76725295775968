import React, {useRef, useState} from "react";
import './ConfirmSmsCodeForm.scoped.scss';
import {CountdownTimer, InputSmsCode} from "components/common/index";
import smsConfig from 'configs/sms';
import {useEffectOnMount, useEffectOnUpdate, useErrorCallback, useLoading, useProfileData} from "hooks";
import {createSmsFormStateStorage} from "../../../../di";
import {Phone} from "../../../../api-client";
import formatPhone from "../../../../formatters/formatPhone";
import {Button, PrimaryButton} from "../../buttons";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {CSSTransition} from "react-transition-group";

type Props = {
    sendCallback: any;
    resendCallback: any;
    successCallback: any;
    prefix: string;
    phone?: Phone
}

const maxSecondsToAttempt = smsConfig.smsCodeTimeout;
const smsCodeLength = smsConfig.smsCodeLength;
const maxAttempts = smsConfig.smsMaxAttempts;


const ConfirmSmsCodeForm = ({sendCallback, resendCallback, successCallback, phone, prefix}: Props) => {
  const errorCallback = useErrorCallback();
  const [code, setCode] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [numberAttempts, setNumberAttempts] = useState(maxAttempts);
  const [isLoading, withLoading] = useLoading();
  const [newCodeRequested, setNewCodeRequested] = useState(false);
  const [timeLeft, setTimeLeft] = useState(maxSecondsToAttempt);
  const profileData = useProfileData();
  const [active, setActive] = useState(false);
  const timeoutRef = useRef(0);

  const storage = createSmsFormStateStorage(prefix);

  const phoneTextToShow = () => {
    if (phone !== undefined) {
      return `номер ${formatPhone(phone)}`;
    }
    if (profileData.phone !== undefined) {
      return `номер ${formatPhone(profileData.phone)}`;
    }
    return "Ваш номер";
  };

  const resendCode = async () => {
    const resendCodeCallback = async () => {
      try {
        await resendCallback();
        setNewCodeRequested(true);
        setNumberAttempts(maxAttempts);
      } catch (err: any) {
        await errorCallback(err);
      }
    };
    await withLoading(resendCodeCallback);
  };

  useEffectOnUpdate(() => {
    const callback = () => {
      if (newCodeRequested) {
        const date = new Date();
        date.setSeconds(date.getSeconds() + timeLeft);
        storage.set({
          attemptsLeft: numberAttempts,
          nextTryDate: date
        });
      } else {
        storage.remove();
        setTimeLeft(maxSecondsToAttempt);
      }
    };
    callback();
    return (() => {
      callback();
    });
  }, [newCodeRequested]);

  useEffectOnUpdate(() => {
    storage.setAttempts(numberAttempts);
  }, [numberAttempts]);

  useEffectOnMount(() => {
    const mountCallback = () => {
      const data = storage.get();
      if (data) {
        setNumberAttempts(data.attemptsLeft);
        if (data.nextTryDate) {
          const timeDiff = Math.round((data.nextTryDate.getTime() - new Date().getTime()) / 1000);
          if (timeDiff > 0) {
            setTimeLeft(timeDiff);
            setNewCodeRequested(true);
          } else {
            storage.remove();
            setTimeLeft(maxSecondsToAttempt);
          }
        }
      }
    };
    mountCallback();
  });

  useEffectOnUpdate(() => {
    setIsFilled(smsCodeLength === code.length);
  }, [code]);

  const sendCode = async () => {
    const sendCodeCallback = async () => {
      try {
        ym(90215442,'reachGoal','send-code-button');
        const result: any = await sendCallback(code);
        storage.remove();
        await successCallback(result);
      } catch (err: any) {
        await errorCallback(err);
        setNumberAttempts((prevNumber) => prevNumber - 1);
      }
    };
    await withLoading(sendCodeCallback);
  };

  const onHover = () => {
    clearTimeout(timeoutRef.current);
    setActive(true);
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false);
    }, 250);
  };
  const onTimerExpire = async () => {
    setNewCodeRequested(false);
  };

  return (
    <div className="confirm-form">
      <div className="confirm-form__header">
        Мы отправили код подтверждения на { phoneTextToShow() }
      </div>
      <div className="confirm-form__input">
        <InputSmsCode onChange={setCode} code={code} />
        { isFilled && <PrimaryButton expanded color={PrimaryButtonColor.GREEN}>
          <Button onClick={sendCode} disabled={isLoading}>
            Отправить
          </Button>
        </PrimaryButton> }
      </div>
      <div className="confirm-form__controls">
        { isLoading && <div>
          Загрузка
        </div> }
        {
          newCodeRequested ? <div className="confirm-form__countdown">
            Получить новый код можно через <CountdownTimer seconds={timeLeft} callback={onTimerExpire} />
          </div> : <div>
            <Button onClick={resendCode} className="confirm-form__action">
              Получить новый код
            </Button>
          </div>
        }
        <div
          className="sms-help"
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
        >
          <Button className="confirm-form__action">
            Не приходит СМС?
          </Button>
          <CSSTransition
            in={active}
            timeout={250}
            classNames="sms-help__answer-"
          >
            <div className="sms-help__answer">
              <div className="sms-help__text">
                1. Проверьте, верно ли указан номер телефона и отправьте запрос на получение <b>кода</b> ещё раз. <br/><br/>
                2. Перезагрузите телефон и проверьте наличие <b>СМС.</b> <br/><br/>
                3. Освободите память в телефоне. Возможно, её недостаточно, чтобы получить сообщение. <br/><br/>
                4. Повторите попытку немного позже.
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSmsCodeForm;