import React, {useEffect, useMemo, useRef, useState} from "react";
import {Outlet} from "react-router-dom";
import {
  Block,
  BoldText,
  Button, Checkbox, ColoredText,
  Container, CustomLink,
  DashboardHeader, InputErrors,
  InputMoney,
  PrimaryButton,
  PrimaryHeading,
  Section,
  Toggle
} from "components";
import Heading, {HeadingType} from "components/common/typography/headings/Heading/Heading";
import {EconomicClasses, LegalClasses, MaturityDateSlider, YearProfitSlider} from "components/pages/autoinvest";
import {Money as MoneyComponent} from "components/common";
import {PrimaryButtonColor} from "components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import Information from "components/common/utils/Information";
import {TextColor} from "components/common/typography/texts/ColoredText/ColoredText";
import {MoneyMode} from "components/common/utils/Money/Money";
import {
  cn,
  Errors as ValidationErrors,
  MAX_MATURITY_DATE,
  MAX_YEAR_PROFIT, MIN_MATURITY_DATE, MIN_YEAR_PROFIT,
  resultIf,
  RouteDictionary,
  SliderValue
} from "utils";
import {useEffectOnUpdate, useErrorCallback, useNavigateByName, useProfileData, useToggle} from "hooks";
import {AutoInvestmentSettings, Money} from "api-client";
import {createAutoInvestmentSettingsManager} from "../../../../../di";

import './Autoinvest.scoped.scss';
import 'rc-slider/assets/index.css';
import {useWindowWidth} from "../../../../../components/pages/detailed-project/hooks";

export type AutoInvestErrors = {
    maxAmount?: ValidationErrors,
    maxTotalAmount?: ValidationErrors,
    economicalClasses?: ValidationErrors,
    legalClasses?: ValidationErrors,
    minAmount?: ValidationErrors,
}
const AutoInvest = () => {
  const profileData = useProfileData();
  const [active, toggle] = useToggle(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [turning, setTurning] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleError = useErrorCallback();
  const [minAmount, setMinAmount] = useState<Money>(new Money(0, "RUB"));
  const [maxAmount, setMaxAmount] = useState<Money>(new Money(0, "RUB"));
  const [maxTotalAmount, setMaxTotalAmount] = useState<Money>(new Money(0, "RUB"));
  const [totalInvestedAmount, setTotalInvestedAmount] = useState<Money>(new Money(0, "RUB"));
  const [left, setLeft] = useState<Money>(new Money(0, "RUB"));
  const [minAnnualProfit, setMinAnnualProfit] = useState<number | undefined>(undefined);
  const [maxAnnualProfit, setMaxAnnualProfit] = useState<number | undefined>(undefined);
  const [minSimpleAnnualProfit, setMinSimpleAnnualProfit] = useState<number | undefined>(undefined);
  const [maxSimpleAnnualProfit, setMaxSimpleAnnualProfit] = useState<number | undefined>(undefined);
  const [minTerm, setMinTerm] = useState<number | undefined>(undefined);
  const [maxTerm, setMaxTerm] = useState<number | undefined>(undefined);
  const [economicalClasses, setEconomicalClasses] = useState<Array<string>>([]);
  const [legalClasses, setLegalClasses] = useState<Array<string>>([]);
  const [isActivated, setIsActivated] = useState(true);
  const [incasso, setIncasso] = useState(false);
  const [isEnableSameBorrower, setEnableSameBorrower] = useState(false);
  const [errors, setErrors] = useState<AutoInvestErrors>({});
  const [autoInvestValid, setAutoInvestValid] = useState(false);
  const [isEventConducting, setIsEventConducting] = useState(false);
  const focused = useRef<boolean>(false);
  const isMobile = useWindowWidth();
  const validateParam = (param: number | null | undefined, border1: number, border2: number) => {
    return param === border1 || param === border2 || param === null ? undefined : param;
  };
  const currentSettings = useMemo((): AutoInvestmentSettings => {
    return {
      minAmount,
      maxAmount,
      maxTotalAmount,
      minAnnualProfit,
      maxAnnualProfit,
      maxSimpleAnnualProfit,
      minSimpleAnnualProfit,
      minTerm,
      maxTerm,
      economicalClasses,
      legalClasses,
      incasso,
      isActivated,
      totalInvestedAmount,
      isEnableSameBorrower
    };
  }, [
    minAmount,
    maxAmount,
    maxTotalAmount,
    minAnnualProfit,
    maxAnnualProfit,
    minSimpleAnnualProfit,
    maxSimpleAnnualProfit,
    minTerm,
    maxTerm,
    economicalClasses,
    legalClasses,
    incasso,
    isActivated,
    totalInvestedAmount,
    isEnableSameBorrower
  ]);
  const handleToggle = () => {
    if (accepted) {
      (async () => {
        setTurning(true);
        try {
          const manager = await createAutoInvestmentSettingsManager();
          await manager.changeState(!active);
        } catch (error: any) {
          await handleError(error);
        } finally {
          setTurning(false);
        }
      })();
    }
    toggle();
    setIsActivated(!active);
  };

  const withoutSettings = (currentSettings.isActivated && active) || (!currentSettings.isActivated && !active);

  const setCurrentSettings = (settings: AutoInvestmentSettings) => {
    setMinAmount(settings.minAmount);
    setMaxAmount(settings.maxAmount);
    setMaxTotalAmount(settings.maxTotalAmount);
    setMinAnnualProfit(validateParam(settings.minAnnualProfit, 0, MIN_YEAR_PROFIT));
    setMaxAnnualProfit(validateParam(settings.maxAnnualProfit, 0, MAX_YEAR_PROFIT));
    setMinSimpleAnnualProfit(validateParam(settings.minSimpleAnnualProfit, 0, MIN_YEAR_PROFIT));
    setMaxSimpleAnnualProfit(validateParam(settings.maxSimpleAnnualProfit, 0, MAX_YEAR_PROFIT));
    setMinTerm(validateParam(settings.minTerm, 0, MIN_MATURITY_DATE));
    setMaxTerm(validateParam(settings.maxTerm, 0, MAX_MATURITY_DATE));
    setEconomicalClasses(settings.economicalClasses);
    setLegalClasses(settings.legalClasses);
    setIncasso(settings.incasso);
    setIsActivated(settings.isActivated);
    setTotalInvestedAmount(settings.totalInvestedAmount);
    setEnableSameBorrower(settings.isEnableSameBorrower);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const manager = await createAutoInvestmentSettingsManager();
        const results = await manager.getUserSettings();
        const globalState = await manager.globalState();
        setIsEventConducting(globalState.isEventConducting);
        if (results) {
          setCurrentSettings(results);
          setLeft(new Money(results.maxTotalAmount.amount - results.totalInvestedAmount.amount, results.maxTotalAmount.currencyCode));
          setAccepted(true);
          if (!((results.isActivated && active) || (!results.isActivated && !active))) toggle();
        }

      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    })();
  }, []);
  const navigate = useNavigateByName();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const manager = await createAutoInvestmentSettingsManager();
      const result = await manager.setUserSettings(currentSettings);
      navigate(RouteDictionary.AUTOINVEST_CONFIRMATION, {confirmationUuid: result.uuid});
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMaturityDateChange = (value: SliderValue) => {
    setMinTerm(validateParam(value.min, 0, MIN_MATURITY_DATE));
    setMaxTerm(validateParam(value.max, 0, MAX_MATURITY_DATE));
  };

  const handleYearProfitChange = (value: SliderValue) => {
    setMinAnnualProfit(validateParam(value.min, 0, MIN_YEAR_PROFIT));
    setMaxAnnualProfit(validateParam(value.max, 0, MAX_YEAR_PROFIT));
  };

  const handleYearSimpleProfitChange = (value: SliderValue) => {
    setMinSimpleAnnualProfit(validateParam(value.min, 0, MIN_YEAR_PROFIT));
    setMaxSimpleAnnualProfit(validateParam(value.max, 0, MAX_YEAR_PROFIT));
  };

  const onValidated = (value: boolean) => {
    setAutoInvestValid(value);
  };
  const validateBlock = (): boolean => {
    const errors: AutoInvestErrors = {};
    if (maxAmount.amount === 0) {
      errors.maxAmount = ["Укажите максимальную сумму"];
    }
    if (maxTotalAmount.amount === 0) {
      errors.maxTotalAmount = ["Укажите начальный лимит"];
    }
    if (economicalClasses.length === 0) {
      errors.economicalClasses = ["Укажите категорию долга"];
    }
    if (legalClasses.length === 0) {
      errors.legalClasses = ["Укажите категорию долга"];
    }
    if (maxAmount.amount > maxTotalAmount.amount) {
      errors.maxTotalAmount = ["Максимальная сумма сделки больше максимального размера инвестирования"];
    }
    if (minAmount.amount > maxAmount.amount) {
      errors.minAmount = ["Минимальная сумма сделки больше максимальной суммы сделки"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    onValidated(validateBlock());
  }, [
    currentSettings.maxAmount,
    currentSettings.minAmount,
    currentSettings.maxTotalAmount,
    currentSettings.economicalClasses,
    currentSettings.legalClasses,
  ]);

  const handleInputChanged = (money: Money) => {
    setMaxTotalAmount(money);
    setLeft(money);
  };
  const disabledCondition = !profileData.agentCode
        || !autoInvestValid
        || (maxAmount.amount > maxTotalAmount.amount)
        || (minAmount.amount > maxAmount.amount)
        || isEventConducting;
  return (
    <Section>
      <Container>
        <DashboardHeader>
          <DashboardHeader.Main>
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                Общие настройки автоинвеста
              </Heading>
            </PrimaryHeading>

            {!initialLoading && <div className="autoinvest__toggle">
              <div
                className={cn(
                  "autoinvest__toggle-text",
                  resultIf(active, "active")
                )}
              >
                Вход по умолчанию
              </div>
              <Toggle disabled={!withoutSettings || turning || isEventConducting} active={active} size={isMobile ? 's' : 'm'}
                onToggled={handleToggle}
              />
            </div>}
            <p className="autoinvest__text">
              Настройки, применяемые к проектам в момент публикации.<br/>
              При этом по каждому проекту можно уточнить настройки внутри презентации в пределах заданных
              параметров
            </p>
          </DashboardHeader.Main>
        </DashboardHeader>
        {initialLoading ? <div className="autoinvest-placeholder">
          Загружаем Ваши настройки, пожалуйста, подождите...
        </div> : <div className="autoinvest">
          <div className="autoinvest__element">
            <Block>
              <Block.Content>
                <Block.Header className="autoinvest__block-heading" withPadding>
                  Настройка по параметрам сделки
                </Block.Header>
                <Block.Body withPadding={false} className="autoinvest__settings-body">
                  <div className="autoinvest__container">
                    <div className="autoinvest__parameters-grid">
                      <div>
                        <BoldText>
                          <div className="autoinvest__heading">
                            Минимальная сумма сделки
                          </div>
                        </BoldText>
                        <InputMoney
                          money={minAmount}
                          onValueChanged={setMinAmount}
                          onFocus={() => focused.current = true}
                          onBlur={() => focused.current = false}
                        />
                      </div>
                      <div>
                        <BoldText>
                          <div className="autoinvest__heading">
                            Максимальная сумма сделки
                          </div>
                        </BoldText>
                        <InputMoney
                          money={maxAmount}
                          onValueChanged={setMaxAmount}
                          onFocus={() => focused.current = true}
                          onBlur={() => focused.current = false}
                        />
                        {errors.maxAmount && <InputErrors errors={errors.maxAmount}/>}
                      </div>
                    </div>
                  </div>
                  <div className="autoinvest__container">
                    <BoldText>
                      <div className="autoinvest__heading">
                        Категория долга
                      </div>
                    </BoldText>
                    <div className="autoinvest__parameters-grid">
                      <div className="autoinvest__debt-class">
                        <div className="autoinvest__text">
                          Экономическая сторона
                        </div>
                        <EconomicClasses
                          selectedClasses={economicalClasses}
                          onSelected={setEconomicalClasses}
                        />
                        {errors.economicalClasses &&
                        <InputErrors errors={errors.economicalClasses}/>}
                      </div>
                      <div className="autoinvest__debt-class">
                        <div className="autoinvest__text">
                          Юридическая сторона
                        </div>
                        <LegalClasses
                          selectedClasses={legalClasses}
                          onSelected={setLegalClasses}
                        />
                        {errors.legalClasses && <InputErrors errors={errors.legalClasses}/>}
                      </div>
                    </div>
                  </div>
                  <div className="autoinvest__container">
                    <div className="autoinvest__parameters-grid">
                      <div>
                        <BoldText>
                          <div className="autoinvest__heading">
                            <span className="d-block">
                              Прогнозируемый
                            </span>
                            <span className="d-block">
                              срок взыскания
                            </span>
                          </div>
                        </BoldText>
                        <div className="autoinvest__slider-container">
                          <MaturityDateSlider
                            handleChange={handleMaturityDateChange}
                            currentValue={[minTerm, maxTerm]}
                          />
                        </div>
                      </div>
                      <div>
                        <BoldText>
                          <div className="autoinvest__heading">
                            <span className="d-block">
                              Прогнозируемая
                            </span>
                            <span className="d-block">
                              годовая доходность
                            </span>
                          </div>
                        </BoldText>
                        <div className="autoinvest__slider-container">
                          <YearProfitSlider
                            handleChange={handleYearProfitChange}
                            currentValue={[minAnnualProfit, maxAnnualProfit]}
                          />
                        </div>
                      </div>
                      <div>
                        <BoldText>
                          <div className="autoinvest__heading">
                            <span className="d-block">
                              Прогнозируемая
                            </span>
                            <span className="d-block">
                              простая доходность
                            </span>
                          </div>
                        </BoldText>
                        <div className="autoinvest__slider-container">
                          <YearProfitSlider
                            handleChange={handleYearSimpleProfitChange}
                            currentValue={[minSimpleAnnualProfit, maxSimpleAnnualProfit]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="autoinvest-incasso">
                    <div className="autoinvest-incasso__title">
                      Дополнительные условия
                    </div>
                    <div className="autoinvest-incasso__container">
                      <Checkbox id="incasso" checked={incasso}
                        onChange={() => setIncasso((agree) => !agree)}>Применять настройки к проектам с Инкассо
                        цессией</Checkbox>
                    </div>
                    <div className="same-borrower__container">
                      <Checkbox id="enable-same-borrower" checked={isEnableSameBorrower}
                        onChange={() => setEnableSameBorrower((agree) => !agree)}>Применять настройки к повторным сделкам с одним должником</Checkbox>
                    </div>
                  </div>
                </Block.Body>
              </Block.Content>
            </Block>
          </div>
          <div className="autoinvest__element">
            <Block>
              <Block.Content>
                <Block.Header className="autoinvest__block-heading" withPadding>
                  Лимит поручения
                </Block.Header>
                <Block.Body withPadding={false}>
                  <div className="block__container">
                    <div className="autoinvest__container">
                      <div className="autoinvest__heading">
                        <div>Начальный лимит</div>
                        <div className="autoinvest__heading-info">
                          <Information position={"left"}>
                            Начальный лимит - максимальная
                            сумма, которую вы готовы распределить с помощью
                            автоинвеста. При достижении этой суммы Автоинвест
                            более не будет работать. Сбрасывается при обновлении
                            настроек
                          </Information>
                        </div>
                      </div>
                      <div>
                        <InputMoney
                          money={maxTotalAmount}
                          onValueChanged={(money) => handleInputChanged(money)}
                          onFocus={() => focused.current = true}
                          onBlur={() => focused.current = false}
                        />
                        {errors.maxTotalAmount && <InputErrors errors={errors.maxTotalAmount}/>}
                        {errors.minAmount && <InputErrors errors={errors.minAmount}/>}
                      </div>

                      <div className="autoinvest__left">
                        Остаток лимита:
                        <MoneyComponent mode={MoneyMode.ADDITIONAL} money={left}/>
                      </div>
                    </div>

                    <div className="autoinvest__container">
                      <div className="autoinvest__submit">
                        {isEventConducting && <InputErrors
                          errors={['Изменение настроек недоступно во время проведения автоинвеста по проекту. Возможность изменить настройки скоро появится.']}/>}
                        <div>
                          {!profileData.agentCode &&
                          <div className="autoinvest__submit__info"><Information
                            className="autoinvest__submit__info__block">
                            Чтобы настроить, вам необходимо подписать агентский договор
                          </Information></div>}
                          <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
                            <Button onClick={handleSubmit} disabled={disabledCondition}>
                              Подтвердить настройки
                            </Button>
                          </PrimaryButton>
                        </div>
                      </div>
                      <div className="autoinvest__rules">
                        Подтверждая настройки параметров Автоинвеста вы соглашаетесь с <ColoredText
                          color={TextColor.PRIMARY_GREEN}>
                          <CustomLink to={RouteDictionary.RULES_AUTOINVEST} target={'_blank'}>
                            Правилами автоматического инвестирования.
                          </CustomLink>
                        </ColoredText> Агентское поручение, выданное через функционал Автоинвеста,
                        является бессрочным и действует до обновления настроек либо до
                        распределения всей суммы, указанной в настройках.
                      </div>
                    </div>
                  </div>
                </Block.Body>
              </Block.Content>
            </Block>
          </div>
          <Outlet/>
        </div>}
      </Container>
    </Section>
  );
};

export default AutoInvest;