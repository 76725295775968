import React, {useState} from "react";
import {useEffectOnMount, useErrorCallback, useNavigateByName, useProfileData} from '../../../../hooks';
import {Identification as ApiIdentification, LenderType, translateLenderType,} from "../../../../api-client";
import './Identification.scoped.scss';
import {
  ColoredText,
  Container,
  CustomLink,
  DashboardHeader,
  Heading,
  PrimaryHeading,
  Section
} from "../../../../components";
import {useDispatch} from "react-redux";
import {setIdentificationDraft} from "../../../../store/profile";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import IdentificationTypeChanger from "../../../../components/user/identification/IdentificationTypeChanger";
import {
  IdentificationEntrepreneur,
  IdentificationIndividual,
  IdentificationLegalEntity
} from "../../../../components/user/identification";
import {RouteDictionary} from "../../../../utils";
import {TextColor} from "../../../../components/common/typography/texts/ColoredText/ColoredText";
import {createIdentificationManager} from "../../../../di";

const Identification = () => {
  const profileData = useProfileData();
  const dispatch = useDispatch();
  const [type, setType] = useState(LenderType.INDIVIDUAL);
  const navigate = useNavigateByName();
  const [identification, setIdentification] = useState<ApiIdentification | undefined>(undefined);
  const isVerified = () => {
    return profileData.lenderVerified;
  };
  
  const handleError = useErrorCallback();
  
  useEffectOnMount(() => {
    if (isVerified()) {
      if (profileData.isEntrepreneur) setType(LenderType.ENTREPRENEUR);
      else if (profileData.isLegalEntity) setType(LenderType.LEGAL_ENTITY);
      const callback = async () => {
        try {
          const provider = await createIdentificationManager();
          const identification = await provider.findOne();
          setIdentification(identification);
        } catch (err: any) {
          if (err instanceof Error) {
            await handleError(err);
          }
        }
      };
      callback();
    }
  });

  const handleSubmit = () => {
    dispatch(setIdentificationDraft(true));
    navigate(RouteDictionary.MY_MONEY);
    return;
  };

  return (
    <Container>
      <Section className="identification-section">
        <DashboardHeader>
          <DashboardHeader.Main>
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                {isVerified() ? 'Изменение данных' : 'Верификация инвестора'}
              </Heading>
            </PrimaryHeading>
            <div>
              <ColoredText color={TextColor.PRIMARY_GREEN}>
                {isVerified() ?
                  <CustomLink to={RouteDictionary.PROFILE_CONTROL}>
                    Назад
                  </CustomLink> :
                  <CustomLink to={RouteDictionary.MY_MONEY}>
                    Пропустить
                  </CustomLink>}
              </ColoredText>
            </div>
          </DashboardHeader.Main>
        </DashboardHeader>
        <div>
          {!isVerified() && <div className="identification-type-select">
            <IdentificationTypeChanger
              active={type === LenderType.INDIVIDUAL}
              onClick={() => setType(LenderType.INDIVIDUAL)}
            >
              <div className="identification-type-select--PC">
                {translateLenderType(LenderType.INDIVIDUAL)}
              </div>
              <div className="identification-type-select--mobile">
                Физ. лицо
              </div>
            </IdentificationTypeChanger>
            <IdentificationTypeChanger
              active={type === LenderType.ENTREPRENEUR}
              onClick={() => setType(LenderType.ENTREPRENEUR)}
            >
              {translateLenderType(LenderType.ENTREPRENEUR)}
            </IdentificationTypeChanger>
            <IdentificationTypeChanger
              active={type === LenderType.LEGAL_ENTITY}
              onClick={() => setType(LenderType.LEGAL_ENTITY)}
            >
              <div className="identification-type-select--PC">
                {translateLenderType(LenderType.LEGAL_ENTITY)}
              </div>
              <div className="identification-type-select--mobile">
                Юр. лицо
              </div>
            </IdentificationTypeChanger>
          </div>}
          {(!isVerified() || identification) && <div className="identification-blocks">
            {type === LenderType.INDIVIDUAL &&
            <IdentificationIndividual onSubmit={handleSubmit} identification={identification}/>}
            {type === LenderType.ENTREPRENEUR &&
            <IdentificationEntrepreneur onSubmit={handleSubmit} identification={identification}/>}
            {type === LenderType.LEGAL_ENTITY &&
            <IdentificationLegalEntity onSubmit={handleSubmit} identification={identification}/>}
          </div>}
        </div>
      </Section>
    </Container>
  );
};

export default Identification;