import React, {FC, type ReactNode, useEffect} from 'react';
import {useAppDispatch, useNavigateByName} from '../../../hooks';
import {RouteDictionary} from '../../../utils';
import {Button} from '../../common';
import {OnboardingProvider, useOnboarding} from '../OnboardingModalContext';
import styles from './OnboardingModal.module.scss';
import {AddOnboardingModal, PrimaryButton} from '../../index';
import {PrimaryButtonColor} from '../../common/buttons/decorators/PrimaryButton/PrimaryButton';
import {OnboardingGreeting} from '../OnboardingGreeting';
import {OnboardingGreetingExplanation} from '../OnboardingGreetingExplanation';
import {OnboardingChoosePurposes} from '../OnboardingChoosePurposes';
import {OnboardingRadioModal} from '../OnboardingRadioModal';
import {OnboardingFinal} from '../OnboardingFinal';
import logo from '../../../assets/images/svg/logo-green.svg';
import {set} from '../../../store/overlay';

interface OnboardingModalProps {
  isOpen: boolean;
  step: number;
  onNextStep: () => void;
  onClose: () => void;
  children?: ReactNode;
}

const OnboardingModalContent: FC = () => {
  const {currentStep, isActive, changeCurrentStep, setIsActive, isMobile, setIsMobile, jsonData} = useOnboarding();
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  
  const stepsContentNotMobile: Record<number, React.ReactNode> = {
    0: <OnboardingGreeting />,
    1: <OnboardingChoosePurposes />,
    2: <OnboardingRadioModal currentStep={2} />,
    3: <OnboardingRadioModal currentStep={3} />,
    4: <OnboardingFinal />,
  };
  const stepsContentMobile: Record<number, React.ReactNode> = {
    0: <OnboardingGreeting />,
    1: <OnboardingGreetingExplanation />,
    2: <OnboardingChoosePurposes />,
    3: <OnboardingRadioModal currentStep={2} />,
    4: <OnboardingRadioModal currentStep={3} />,
    5: <OnboardingFinal />,
  };
  
  const stepsContent = isMobile ? stepsContentMobile : stepsContentNotMobile;
  const isLastStep = currentStep === Object.keys(stepsContent).length - 1;
  const isFirstStep = currentStep === 0;
  
  
  const handleClose = () => {
    setIsActive(false);
    dispatch(set(false));
    navigate(RouteDictionary.IDENTIFICATION);
  };
  const handleNextStep = () => {
    if (!validateCurrentStep()) {
      return;
    }

    if (isLastStep) {
      handleClose();
      return;
    }

    changeCurrentStep(
      Math.min(currentStep + 1, Object.keys(stepsContent).length - 1)
    );
  };
  
  const validateCurrentStep = (): boolean => {
    if (!jsonData) {
      return false;
    }
    const {selectedPurposes, projectsCount, sourceDetails} = jsonData;
    
    if (isMobile) {
      if (currentStep === 1) {
        return true;
      } else if (currentStep === 2) {
        return selectedPurposes && selectedPurposes.length > 0;
      } else if (currentStep === 3) {
        return !!projectsCount;
      } else if (currentStep === 4) {
        return !!sourceDetails && !!sourceDetails.option;
      }
    } else {
      if (currentStep === 1) {
        return selectedPurposes && selectedPurposes.length > 0;
      } else if (currentStep === 2) {
        return !!projectsCount;
      } else if (currentStep === 3) {
        return !!sourceDetails && !!sourceDetails.option;
      }
    }
    
    return true;
  };

  const handlePreviousStep = () => {
    changeCurrentStep(Math.max(currentStep - 1, 0));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        if(isLastStep) {
          handleClose();
          return;
        }
        handleNextStep();
      } else if (event.key === 'ArrowLeft') {
        handlePreviousStep();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentStep]);
  
  const Paginator = () => (
    <div className={styles.paginator}>
      {Object.keys(stepsContent).map((_, index) => (
        <div
          key={index}
          className={`${styles.paginatorDot} ${
            currentStep === index ? styles['paginatorDot--active'] : ''
          }`}
        ></div>
      ))}
    </div>
  );
  
  if (isMobile) {
    return <div className={styles.onboardingModal__content}>
      {stepsContent[currentStep]}
      <div className={styles.onboardingMobile__footer}>
        <Paginator />
        <PrimaryButton
          color={!validateCurrentStep()? PrimaryButtonColor.GRAY: PrimaryButtonColor.GREEN}
          className={styles.nextButton}
          expanded={true}
          disabled={!validateCurrentStep()}
        >
          <Button onClick={handleNextStep} disabled={!validateCurrentStep()}>
            Дальше
          </Button>
        </PrimaryButton>
      </div>
    </div>;
  } else {
    return (
      <AddOnboardingModal active={isActive} onClose={handleClose}>
        <div
          className={`${styles.onboardingModal__content} ${
            isFirstStep ? styles.backgroundActive : ''
          }`}
        >
          {!isFirstStep && !isLastStep && (
            <div className={`'text-gray-3' ${styles.onboardingModal__stepsCount}`}>
              Шаг {currentStep} из {Object.keys(stepsContent).length - 1}
            </div>
          )}
          {stepsContent[currentStep]}
          {!isLastStep && (
            <div className={styles.onboardingModal__footer}>
              {!isFirstStep && (
                <PrimaryButton
                  color={PrimaryButtonColor.GRAY}
                  className={styles.previousButton}
                >
                  <Button onClick={handlePreviousStep}>Назад</Button>
                </PrimaryButton>
              )}
              {!isLastStep && (
                <PrimaryButton
                  color={!validateCurrentStep()? PrimaryButtonColor.GRAY: PrimaryButtonColor.GREEN}
                  className={styles.nextButton}
                  disabled={!validateCurrentStep()}
                >
                  <Button onClick={handleNextStep} disabled={!validateCurrentStep()}>
                    {isFirstStep ? 'Хорошо' : 'Далее'}
                  </Button>
                </PrimaryButton>
              )}
              {isFirstStep && <img src={logo} alt={'logo'} className={styles.logo__green} />}
            </div>
          )}
        </div>
      </AddOnboardingModal>
    );
  }
};

const OnboardingModal: FC<OnboardingModalProps> = ({isOpen}) => {
  if (!isOpen) return null;
  
  return (
    <OnboardingProvider>
      <OnboardingModalContent />
    </OnboardingProvider>
  );
};

export default OnboardingModal;
