import React, {FC, useContext, useState} from "react";
import {Errors as ValidationErrors, isEmail, numbersOnly} from "../../../../../utils";
import {IdentificationBlock} from "../index";
import {CommonInput, Heading, InputErrors, RadioButton, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import RadioButtonGroup, {Direction} from "../../../../common/form/RadioButtonGroup/RadioButtonGroup";
import {Identification, UserGender} from "../../../../../api-client";
import IdentificationContext from "../../../../context/IdentificationContext";
import {useEffectOnMount, useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import * as constants from 'utils/constants';
import {IdentificationDateSelect} from "../../../../pages/identification";
import moment from "moment/moment";
import {ProfileState} from "../../../../../store/profile";
import {SnippetSvg} from "../../../../svg";
import EntrepreneurScanUpload from "../../../../pages/identification/EntrepreneurScanUpload";

export type IdentificationPersonalErrors = {
    firstName?: ValidationErrors;
    middleName?: ValidationErrors;
    lastName?: ValidationErrors;
    inn?: ValidationErrors;
    ogrnip?: ValidationErrors;
    birthday?: ValidationErrors;
    email?: ValidationErrors;
    birthplace?: ValidationErrors;
    registerAddress?: ValidationErrors;
    entrepreneurDocument?: ValidationErrors;
}

interface Props {
    isEntrepreneur?: boolean;
    isLegalEntity?: boolean;
    onValidated: (value: boolean) => void;
    identification: Identification | undefined
}

const IdentificationPersonal: FC<Props> = ({isEntrepreneur, isLegalEntity, onValidated, identification}) => {
  const [errors, setErrors] = useState<IdentificationPersonalErrors>({});
  const context: any = useContext(IdentificationContext);
  const profile: ProfileState = useProfileData();
  const isVerified = () => {
    return profile.lenderVerified;
  };
  useEffectOnMount(() => {
    const person = identification?.person;
    if (identification && person) {
      context.setFirstName(person.firstName);
      context.setMiddleName(person.middleName);
      context.setLastName(person.lastName);
      context.setInn(identification.inn.replaceAll(' ', ''));

      if (profile.email) {
        context.setEmail(profile.email.email);
      }
      context.setBirthplace(person.birthplace);
      context.setBirthday(moment(person.birthday).toDate());
      context.setRegisterAddress(identification.registerAddress);
      if (identification.ogrnip) {
        context.setOgrnip(identification.ogrnip);
      }
      if (identification.entrepreneurDocument) {
        context.setEntrepreneurDocument(identification.entrepreneurDocument);
      }
    }
  });

  const validateBlock = (): boolean => {
    const errors: IdentificationPersonalErrors = {};
    if (context.firstName === "") {
      errors.firstName = ["Имя обязательно для заполнения"];
    }
    if (context.middleName === "") {
      errors.middleName = ["Фамилия обязательна для заполнения"];
    }
    if (context.inn === "") {
      errors.inn = ["ИНН обязателен для заполнения"];
    } else if (!numbersOnly(context.inn)) {
      errors.inn = ["ИНН должен состоять из цифр"];
    } else if (context.inn.length !== constants.INN_INDIVIDUAL_LENGTH) {
      errors.inn = [`Длина ИНН - ${constants.INN_INDIVIDUAL_LENGTH} символов`];
    }
    if (isEntrepreneur) {
      if (context.ogrnip === "") {
        errors.ogrnip = ["ОГРНИП обязателен для заполнения"];
      } else if (!numbersOnly(context.ogrnip)) {
        errors.ogrnip = ["ОГРНИП должен состоять из цифр"];
      } else if (context.ogrnip.length !== constants.MAX_OGRNIP_LENGTH) {
        errors.ogrnip = [`Длина ОГРНИП - ${constants.MAX_OGRNIP_LENGTH} символов`];
      }
      if (context.entrepreneurDocument.length === 0) {
        errors.entrepreneurDocument = ["Приложите файл со сканом документа"];
      }
    }
    //TODO: ADD BIRTHDAY ERRORS
    if (context.email === "") {
      errors.email = ["Email обязателен для заполнения"];
    } else if (!isEmail(context.email)) {
      errors.email = ["Неверный формат email"];
    }
    if (context.birthplace === "") {
      errors.birthplace = ["Место рождения обязательно для заполнения"];
    }
    if (context.registerAddress === "") {
      errors.registerAddress = ["Место регистрации обязательно для заполнения"];
    }
    else if (context.registerAddress.length < constants.MIN_REGISTRATION_LENGTH) {
      errors.registerAddress = ["Низкий уровень детализации адреса"];
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const personalHeading = () => {
    if (isLegalEntity) {
      return <SecondaryHeading>
        <Heading headingType={HeadingType.H2}>
          Персональные данные руководителя юридического лица
        </Heading>
      </SecondaryHeading>;
    }
    else {
      return <SecondaryHeading>
        <Heading headingType={HeadingType.H2}>
          Персональные данные
        </Heading>
      </SecondaryHeading>;
    }
  };

  useEffectOnUpdate(() => {
    onValidated(validateBlock());
  }, [
    context.middleName,
    context.firstName,
    context.lastName,
    context.inn,
    context.ogrnip,
    context.email,
    context.birthplace,
    context.registerAddress,
    context.birthday,
    context.entrepreneurDocument,
  ]);
  return (
    <IdentificationBlock>
      <IdentificationBlock.Info>
        lorem ipsum
      </IdentificationBlock.Info>
      { personalHeading() }
      <div className="form__group">
        <CommonInput
          value={context.lastName}
          onChange={(value) => {
            !isVerified() && ym(90215442,'reachGoal','verification-surname-input');
            context.setLastName(value);
          }}
          title="Фамилия"
          id="lastName"
          required
        />
        {errors.lastName && <InputErrors errors={errors.lastName}/>}
      </div>
      <div className="form__group">
        <CommonInput
          value={context.firstName}
          onChange={(value) => {
            !isVerified() && ym(90215442,'reachGoal','verification-name-input');
            context.setFirstName(value);
          }}
          title="Имя"
          id="firstName"
          required
        />
        {errors.firstName && <InputErrors errors={errors.firstName}/>}
      </div>
      <div className="form__group">
        <CommonInput
          value={context.middleName}
          onChange={(value) => {
            !isVerified() && ym(90215442,'reachGoal','verification-patronymic-input');
            context.setMiddleName(value);
          }}
          title="Отчество"
          id="middleName"
          required={false}
        />
        {errors.middleName && <InputErrors errors={errors.middleName}/>}
      </div>
      <div className="form__group">
        <CommonInput
          value={context.inn}
          onChange={(value) => {
            !isVerified() && ym(90215442,'reachGoal','verification-inn-input');
            context.setInn(value);
          }}
          title="ИНН"
          id="inn"
          required
        />
        {errors.inn && <InputErrors errors={errors.inn}/>}
      </div>
      <div className="form__group">
        <IdentificationDateSelect
          header="Дата рождения"
          value={context.birthday}
          onSelected={context.setBirthday}
        />
        {errors.birthday && <InputErrors errors={errors.birthday}/>}
      </div>
      { !isVerified() &&
      <div className="form__group">
        <CommonInput
          value={context.email}
          onChange={(value) => {
            !isVerified() && ym(90215442,'reachGoal','verification-email-input');
            context.setEmail(value);
          }}
          title="Email"
          id="email"
          required
          disabled={profile.lenderVerified}
        />
        {errors.email && <InputErrors errors={errors.email}/>}
      </div>
      }
      <div className="form__group">
        <label className="form__label" htmlFor="gender">
          Пол
        </label>
        <RadioButtonGroup direction={Direction.HORIZONTAL}>
          <RadioButton
            id="gender_male"
            onChange={() => context.setGender(UserGender.MALE)}
            checked={context.gender === UserGender.MALE}>
            Мужской
          </RadioButton>
          <RadioButton
            id="gender_female"
            onChange={() => context.setGender(UserGender.FEMALE)}
            checked={context.gender === UserGender.FEMALE}
          >
            Женский
          </RadioButton>
        </RadioButtonGroup>
      </div>
      <div className="form__group">
        <CommonInput
          value={context.birthplace}
          onChange={(value) => context.setBirthplace(value)}
          title="Место рождения"
          id="birthplace"
          required
        />
        {errors.birthplace && <InputErrors errors={errors.birthplace}/>}
      </div>
      <div className="form__group">
        <CommonInput
          value={context.registerAddress}
          onChange={(value) => context.setRegisterAddress(value)}
          title="Адрес регистрации"
          id="registerAddress"
          required
        />
        {errors.registerAddress && <InputErrors errors={errors.registerAddress}/>}
      </div>
      {isEntrepreneur && <div>
        <div className="form__group">
          <CommonInput
            value={context.ogrnip}
            onChange={(value) => {
              !isVerified() && ym(90215442,'reachGoal','verification-ogrnip-input');
              context.setOgrnip(value);
            }}
            title="ОГРНИП"
            id="ogrnip"
            required
          />
          {errors.ogrnip && <InputErrors errors={errors.ogrnip}/>}

        </div>
        <div className="identification__passport-files">
          <div className="identification__file-name">
            <div className="identification__file-snippet identification__file-snippet--green">
              <SnippetSvg fill="#ffffff"/>
            </div>
            Загрузите документ, подтверждающий статус ИП
          </div>
        </div>
        <div className="identification__passport-files-grid">
          <div>
            <EntrepreneurScanUpload/>
            {errors.entrepreneurDocument && <InputErrors errors={errors.entrepreneurDocument}/>}
          </div>
          <div className="identification-files__warning">
            Загружайте изображение в формате jpg, png или pdf. Файл не должен превышать <span
              className="identification-files__warning-size"> 4 Мб.</span>
          </div>
        </div>
      </div>}
    </IdentificationBlock>
  );
};

export default IdentificationPersonal;