import React, {useCallback, useState} from "react";
import {useErrorCallback, useProfileData, useToggle} from 'hooks';
import {DataCollection} from "utils";
import {PrimaryMarketProject} from "api-client";
import {createProjectProvider} from "../../../../../../di";
import {Block, Pagination,} from "components";
import ProjectsFilter, {Filter} from "components/pages/projects/filter/ProjectsFilter/ProjectsFilter";
import {defaultProjectsFilter} from "components/pages/projects/filter/ProjectsFilter/utils";
import ProjectsHeader from "components/pages/projects/filter/ProjectsHeader/ProjectsHeader";
import OnboardingModal from '../../../../../../components/onboarding/OnboardingModal/OnboardingModal';
import ProjectsContextProvider, {ProjectsContextType} from "../ProjectsContext";
import PrimaryMarketOffer from "components/pages/projects/primary-market/PrimaryMarketOffer";
import './PrimaryMarket.scoped.scss';
import apiClientConfig from "../../../../../../configs/app";
import CommonFilterIcon from "../../../../../../components/filters/CommonFilterIcon";
import {FilterIcon} from "../../../../../../components/filters/CommonFilterIcon/CommonFilterIcon";
import DownloadSvg from "../../../../../../components/svg/DownloadSvg";
import {ProjectsCount} from "../../../../../../api-client/models/project/ProjectsCount";

export interface AutoInvestmentFilter {
  projectUuids?: Array<string>
}

const PrimaryMarket = () => {
  const handleError = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const profileData = useProfileData();
  const [projectsCount, setProjectsCount] = useState<ProjectsCount>({
    closed: 0,
    current: 0,
    fundRaising: 0,
    autoInvestment: 0,
    refunded: 0
  });
  const [projects, setProjects] =
        useState<DataCollection<PrimaryMarketProject>>(new DataCollection());
  const [filterActive, toggleFilter] = useToggle();
  const [favoritesActive, toggleFavorites] = useToggle();

  const [filter, setFilter] = useState<Filter>(defaultProjectsFilter);

  const value: ProjectsContextType = {
    filter,
    setFilter,
    filterActive,
    toggleFilter,
    favoritesActive,
    toggleFavorites
  };

  const loadData = useCallback(async (page: number, perPage: number): Promise<void> => {
    setLoading(true);
    try {
      const provider = await createProjectProvider();
      const projects = await provider.getPrimaryMarketProjects(page, perPage, filter);
      const dataCollection = new DataCollection<PrimaryMarketProject>();
      dataCollection.setCollection(projects);
      setProjects(dataCollection);
      const projectsCountResponse = await provider.getProjectsCount();
      setProjectsCount(projectsCountResponse);
    } catch (err) {
      if (err instanceof Error) await handleError(err);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const projectsList = useCallback(() => {
    return projects.items.map((item: PrimaryMarketProject) => {
      return <PrimaryMarketOffer project={item} key={item.uuid}/>
      ;
    });
  }, [projects]);

  const exportStatistics = async (): Promise<void> => {
    setLoading(true);
    try {
      const provider = await createProjectProvider();
      const response = await provider.exportPrimaryMarketStatistics(1, projects.totalCount, filter);
      window.location.assign(apiClientConfig.apiBaseUrl + response.url);
    } catch (err) {
      if (err instanceof Error) await handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Pagination
      onLoad={loadData}
      count={projects.count}
      totalCount={projects.totalCount}
      filterObject={filter}
      initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
    >
      {
        !profileData.lenderVerified &&
          <OnboardingModal
            onClose={() => false}
            onNextStep={() => true}
            isOpen={true}
            step={1}
          >
            <p>Дополнительная информация, отображаемая в модальном окне.</p>
          </OnboardingModal>
      }
      <ProjectsContextProvider props={value}>
        <Block>
          <Block.Content>
            <Block.Header className="primary-market-block-header" withPadding>
              <ProjectsHeader projectsCount={projectsCount}/>
              <div onClick={exportStatistics} className="primary-market__download">
                <CommonFilterIcon type={FilterIcon.Download}>
                  <DownloadSvg/>
                </CommonFilterIcon>
              </div>
            </Block.Header>
            <Block.Body className="primary-market-block-body" withPadding={false}>
              <ProjectsFilter/>
              <Pagination.Content loading={loading}>
                <ul className="primary-market__list">
                  {projectsList()}
                </ul>
              </Pagination.Content>
            </Block.Body>
          </Block.Content>
        </Block>
        <Pagination.Paginator/>
      </ProjectsContextProvider>
    </Pagination>
  );
};

export default PrimaryMarket;