import React, {useEffect, useState} from 'react';
import {createProfileManager} from '../../../di';
import {useAppDispatch, useErrorCallback, useNavigateByName} from '../../../hooks';
import {set} from '../../../store/overlay';
import {RouteDictionary} from '../../../utils';
import {Button, PrimaryButton} from '../../common';
import {PrimaryButtonColor} from '../../common/buttons/decorators/PrimaryButton/PrimaryButton';
import styles from './OnboardingFinal.module.scss';
import {useOnboarding} from '../OnboardingModalContext';

const OnboardingFinal = () => {
  const {currentStep, changeCurrentStep, setIsActive, isMobile, jsonData} = useOnboarding();
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const handleError = useErrorCallback();
  const [error, setError] = useState<string>();

  const handlePreviousStep = () => changeCurrentStep(currentStep - 1);

  const handleClose = async () => {
    try {
      if (!jsonData) {
        return;
      }
      const profileManager = await createProfileManager();
      await profileManager.addOnboardingAnswers(jsonData);
      setIsActive(false);
      dispatch(set(false));
      navigate(RouteDictionary.IDENTIFICATION);
    } catch (err) {
      if (err instanceof Error) {
        await handleError(err);
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Calendly) {
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/ksutarasova260/30min?hide_event_type_details=1&hide_gdpr_banner=1',
          parentElement: document.getElementById('calendly'),
          prefill: {},
          utm: {},
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [currentStep]);

  return (
    <div className={styles.finalContent}>
      <div className={styles.finalText}>
        <div className={styles.finalTitle}>
          Связаться с инвестиционным менеджером, чтобы быстрее разобраться в инструменте
        </div>
        <div className={styles["text-gray-3"]}>
          В Conomica есть возможность связаться с инвестиционным менеджером, который поможет
          разобраться с инструментом и выбрать стратегии инвестирования, которые подойдут под ваш запрос
        </div>
      </div>
      <PrimaryButton
        color={PrimaryButtonColor.GRAY}
        className={styles.previousButton}
      >
        <Button onClick={handlePreviousStep}>Назад</Button>
      </PrimaryButton>
      {isMobile ? (
        <PrimaryButton
          color={PrimaryButtonColor.BLUE}
          expanded={true}
          large={true}
          className={styles.calendlyButton__mobile}
        >
          <a href={"https://calendly.com/ksutarasova260/30min"}>
            Выбрать время для встречи
          </a>
        </PrimaryButton>
      ) : (
        <div id='calendly' className={styles.calendly__inlineWidget}></div>
      )}
      <div className={`'text-gray-3' ${styles.onboardingModal__stepsCount}`}>
        Шаг {currentStep} из {currentStep}
      </div>
      <PrimaryButton
        color={PrimaryButtonColor.GREEN}
        className={styles.nextButton}
      >
        <Button onClick={handleClose}>Закрыть</Button>
      </PrimaryButton>
    </div>
  );
};

export default OnboardingFinal;
